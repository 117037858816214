<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <div class="form_item">
            <div class="form_label must_input">选择</div>
            <RadioGroup v-model="formData.mo" type="button" button-style="solid">
                <Radio label="1">小程序</Radio>
                <Radio label="2">H5</Radio>
            </RadioGroup>
        </div>
        <div class="form_item">
            <div class="form_label must_input">名称</div>
            <Input class="width_300" type="text" v-model.trim="formData.title" :maxlength="15" show-word-limit></Input>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start must_input">图片</div>
            <div>
                <div class="relative upload_panel">
                    <UploadImage :width="100" :height="100" v-model="formData.pic" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                </div>
                <div v-if="formData.mo == 1" class="tip_txt nowrap">（图片尺寸：750x1052）</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label">序号</div>
            <Input class="width_300" v-model.trim="formData.level" :maxlength="10" show-word-limit></Input>
        </div>

        <div class="form_item">
            <div class="form_label">备注</div>
            <Input class="width_300" type="textarea" :rows="4" :maxlength="50" show-word-limit v-model.trim="formData.info"></Input>
        </div>
        <div class="form_item">
            <div class="form_label">状态</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">开启</Radio>
                <Radio label="0">关闭</Radio>
            </RadioGroup>
        </div>
        <div class="form_item">
            <div class="form_label">跳转链接</div>
            <Input class="width_300" v-model.trim="formData.path" :maxlength="200"></Input>
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import UploadImage from '@/components/uploadImage';
import { reqBannerView, reqSaveBanner } from '@/lib/request/auth';

export default {
    name: 'accountAdd',
    components: {
        UploadImage,
    },
    data() {
        return {
            title: null,
            maxLength: 5,
            picSeq: '|seq|',
            formData: {
                id: null,
                title: null,
                pic: null,
                level: '',
                info: null,
                status: '1',
                mo: '1',
                path: null,
            },
            arrPic: [''],
            isEdit: false,
        };
    },
    mounted() {
        this.routeIdInject();
        this.title = this.isEdit ? '编辑Banner' : '添加Banner';
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        //获取详情
        getDetail() {
            if (this.isEdit) {
                this.showAjaxLoading();
                reqBannerView({ id: this.formData.id })
                    .then(res => {
                        this.dataInjectFormData(res);
                        // this.arrPic = this.formData.pic.split(this.picSeq);
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { title, pic } = params || {};
            let vs = [
                { value: title, tip: '请输入名称' },
                { value: pic, tip: '请选择图片' },
            ];

            hm.validateForm(vs)
                .then(() => {
                    reqSaveBanner(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.goUrl('/bannerManagerList');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.upload_panel {
    width: 100px;
    height: 100px;
    & ~ .upload_panel {
        margin-left: 10px;
    }
}
</style>
